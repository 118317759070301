@use"base/mixins" as m;
@use"base/variables" as v;
.descripcionKits{
    padding: 1rem 0;
    background-image: url(../img/fondokits.webp);
    background-position: 50% 90%;
  
    height: 115rem;
    background-size: cover;
    @include m.telefono{
        height: 105rem;
        
    }
    @include m.desktop{
        background-position: 30% 100%;
    }
}

.descripcionKits_Tarjeta{
    margin: 2rem auto;
    border-radius: 4rem;
    z-index: 0;
    width: 38rem;
    height: 110rem;
    background: linear-gradient(135deg, rgba(94, 94, 94, 0.404),rgba(95, 95, 95, 0.418));
    backdrop-filter:blur(10px);
    -webkit-backdrop-filter: blur(10px);
    border: 1px solid rgba(255, 255, 255, 0.18);
    box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.37);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2rem;
    @include m.telefono{
        width: 46rem;
        height: 100rem;
        
    }
    @include m.tablet{
        width: 50rem;
        
    }
    @include m.tablet{
        width: 75rem;
        
    }

    @include m.desktop{
        margin: 2rem 6rem;
    }
}
.descripcionKits_titulo{
    text-align: center;
    h1{
        color: rgb(255, 255, 255);
        text-shadow: red 2px 2px;
        font-size: 3rem;
        margin: .5rem auto;
        span{
            text-transform: uppercase;
            font-size: 4rem;
            @include m.telefono{
                font-size: 5rem;
            }
            @include m.tablet{
                font-size: 6rem;
            }
        }
        @include m.telefono{
            margin: 1.5rem auto;
            font-size: 4rem;
        }
        @include m.tablet{
            margin: 1rem auto;
            font-size: 4.5rem;
        }

    }
}
.descripcionKits_info{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content:space-between;
    margin: 0 3rem;
    gap: 0;
    h2,h3{
        margin: 0;
        font-size: 1.7rem;
        color: white;
        text-align: center;
        font-family:'Anton', sans-serif;
        font-weight: 500;
        @include m.tablet{
            font-size: 2.2rem;
        }
    }
    h2{
        margin-bottom: 2rem;
        font-weight: 500;
    }
    h3{
        font-weight: 500;
        margin-bottom: 2rem;
    }
    p{
        font-size: 1.7rem;
        font-weight: 600;
        color: white;
    }
    ul{
        
        list-style:none;
        margin: 0 1rem;
        padding: 0;
        @include m.tablet{
            margin: 0 2rem;
        }
    }
    li{
        list-style: none !important;
        font-weight: 500;
        list-style:disc;
        color: white;
        text-align: justify;
        margin-top: 1rem;
        font-size: 1.5rem;
        text-align: justify;
        img{
            width: 2rem;
            margin-right: 1rem;
        }
        @include m.tablet{
            font-size: 2rem;
        }
    }
}
.descripcionKits_piesDePagina{
    padding: 1rem;
    margin: 0 2rem;
    p{ 
        text-align: justify;
        color: white;
        font-size: 1.3rem;
        justify-content: center;
    }
}
.descripcionKits_boton{
    display: flex;
        a{
            font-family:'Anton', sans-serif;
            color: white;
            font-weight: 500;
            width: 30rem;
            padding: 1rem;
            margin: 1rem auto .5rem auto;
            border-radius: 4rem;
            border: none;
            background-color: #cc0100;
            text-align: center;
            @include m.telefono{
                margin: 3rem auto 1rem auto;
                padding: 1.5rem;
            }
            &:hover{
                cursor: pointer;
             }
        }
}