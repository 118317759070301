@use"base/mixins" as m;

.banner{
    background-image: url(../img/banner1.webp);
    height: 100rem;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 5rem 2rem;
    h1{
        font-family: tituloEspecial;
        font-weight: 100;
        font-size: 4rem;
        text-align: center;
        @include m.tablet{
            font-size: 6rem;
        }
        @include m.desktop{
            font-size: 8rem;
        }
    }
    img{
        width: 30rem;
        @include m.desktop{
            width: 40rem;
        }
    }
    .titulo-banner{
        width: 95%;
        @include m.desktop{
            width: 95rem;
        }
    }
    .banner-filtros{
        display: flex;
        background-color: white;
        gap: 1rem;
        height: auto;
        padding: 3rem 3rem;
        border-radius: .5rem;
        @include m.desktop{
            height: 11rem;
            padding: 0rem 3rem;
        }
    }
    .banner-filtros-absolute{
        display: flex;
        gap: 3rem;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        @include m.desktop{
            height: 11rem;
            flex-direction: row;
        }
    }
    .banner-filtros-div{
        background-color: white;
        height: fit-content;
        flex-direction: column;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 25rem;
        border: 2px solid rgb(179, 179, 179);
        border-radius: .5rem;
        color: black;
        @include m.tablet{
            width: 35rem;
        }
        p{
            margin: 0;
        }
    }
    .banner-filtros-div:hover, .banner-filtros-boton:hover{
        cursor: pointer;
    }
    .banner-filtros-boton{
        height: fit-content;
        text-align: center;
        width: 20rem;
        background-color: black;
        border-radius: .5rem;
        p{
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
            margin: 0;
            padding: 2rem 1rem ;
        }
    }
}