@use "variables" as v;
// content permite editar el contenido del mixing fuera del mismo
@mixin telefono{
    @media(min-width:480px){
        @content;      
    }
}
@mixin tablet{
    @media (min-width:768px){
        @content;
    }
}

@mixin desktop{
    @media (min-width:992px){
        @content;
    }
}
@mixin extra{
    @media (min-width:1299px){
        @content;
    }
}
