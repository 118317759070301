@use"base/mixins" as m;

.estadistica{
    overflow-x: hidden;
    height: 55rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 6rem;
    align-items:center;
    padding: 6rem 1rem;
    background: gray;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
    @include m.desktop{
        padding: 6rem;
        align-items:flex-end;
    }
}
.fondo-estadisticas{
    right: 0;
    left: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.336);
}
.instagram{
    background-image: url('../img/f1.webp');
}
.tiktok{
    background-image: url('../img/f2.webp');
}
.youtube{
    background-image: url('../img/f4.webp');
}
.facebook{
    background-image: url('../img/f6.webp');
}

.estadistica-titulo{
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    h3{
        margin: 0;
        font-size: 3rem;
        @include m.tablet{
            font-size: 4rem;
        }
    }
    p{
        font-size: 2rem;
        @include m.tablet{
            font-size: 3rem;
        }
    }
    .estadistica-titulo_logo{
        width: 7rem;
    }
}
.estadistica:nth-child(1), .estadistica:nth-child(3){
    align-items:center;
    @include m.desktop{
        align-items:flex-start;
    }
    .estadistica-titulo_logo{
        margin-right: 3rem;
        margin-left: 0;
    }
    .estadistica-titulo{
        h3{
            text-align: left;
        }
    }
}
.estadistica:nth-child(2), .estadistica:nth-child(4){
    .estadistica-titulo_texto{
        order: 2;
        text-align: left;
        @include m.desktop{
            order: 1;
            text-align: right;
        }
    }
    .estadistica-titulo_logo{
        order: 1;
        margin-right: 3rem;
        margin-left: 0;
        @include m.desktop{
            order: 2;
            margin-right: 0rem;
            margin-left: 3rem;
        }
    }
}
.estadistica-numeros{
    z-index: 2;
    display: flex;
    text-align: center;
    gap: 4rem;
    flex-direction: column;
    @include m.telefono{
        flex-direction: row;
    }
    .numeros{
        p{
            font-size: 1.8rem;
            @include m.tablet{
                font-size: 2rem;
            }
        }
        span{
            font-size: 2rem;
            font-weight: 500;
            @include m.tablet{
                font-size: 2.5rem;
            }
        }
    }
}