footer{
    padding: 2.5rem 0;
    text-align: center;
    h3{
        margin:.5rem 0 .5rem 0;
        font-family: caprasimo;
        font-size: 4rem;
        font-weight: 500;
    }
    p{
        margin: 0;
        font-size: 1.5rem;
    }
}
.footer-redes{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 3rem;
    div{
        border-radius: 4px;
        width: 3rem;
        height: 3rem;
        background: black;
        a{
            text-decoration: none;
        }
        &:hover{
            cursor: pointer;
        }
    }
}
.tiktok:hover{
    background-color: #b92729;
}
.facebook:hover{
    background-color: #b92729;
}
.instagram:hover{
    background-color: #b92729;
}
.youtube:hover{
    background-color: #b92729;
}