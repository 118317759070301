@use "variables" as v;
@use "mixins" as m; 

@font-face{
    font-family: caprasimo;
    src: url("../fonts/Caprasimo-Regular.ttf") format("truetype");

}

@font-face{
    font-family: tituloEspecial;
    src: url("../fonts/Bukhari-Script.ttf") format("truetype");

}



html{
    font-size: 62.5%;
    box-sizing: border-box;
    background: v.$negro;
    color: white;
}
*,
*:before,
*:after{
    box-sizing: inherit;
}
body{
    font-family: v.$fuentePrincipal;
    font-size: 1.6rem;
    &.fijar-body{
        overflow: hidden;
    }
}
.img-fondo{
    background-image: url(../build/4.png);
}
p{
    font-size: 2rem;
    font-weight: 300;
    margin: 0;
}
.contenedor{
    max-width: 200rem;
    margin: 0 auto;
}
a{
    text-decoration: none;
    color: white;
}
h1,h2,h3{
    margin:0 0 4rem 0;
}
h1{
    font-size: 6rem;
    
}
h2{
    font-size: 3rem;
    font-weight: 400;
}
h3{
    font-size: 3rem;
}
img{
    width: 100%;
}
.marker-red {
    background-color: rgba(175, 0, 0, 0.966);
}
li{
    font-weight: 300;
}