@use"base/mixins" as m;
.mapa_filtros{
    border-bottom: 1px solid gray;
    width: 100%;
    background-color: white;
    padding: 2rem 1.5rem;
    display: flex;
    flex-direction: row;
    gap: 2rem;
    justify-content: center;
    align-items: center;
    color: black;
    .filtros_buscador input, .filtros_categoria, .filtros_precio{
        border: 2px solid gray;
        border-radius: .5rem;
        
    }
    @include m.desktop{
        border-bottom: none;
        flex-direction: column;
        width: 25rem;
        padding: 0 1rem;
    }
    @include m.extra{
        width: 35rem;
        padding: 0 2rem;
    }

}
.filtros_buscador{
    position: relative;
    flex: 1;
    svg{
        position: absolute;
        right: 8px;
        height: 100%;
    }
    @include m.desktop{
        flex: none;
    }
}
.selector, .filtros_buscador,  .filtros_buscador input{
    width: 100%;
    display: flex;
    justify-content: space-between;
}
.selector, .filtros_buscador input{
    padding: 1rem;
    align-items: center;
    @include m.tablet{
        padding: 2rem;
    }
    img{
        rotate: 180deg;
        width: 2rem !important;
        height: 1rem;
    }
}
.selector:hover{
    cursor: pointer;
}
.filtros_buscador input{
    font-size: 2rem;
    font-weight: 300;
}
.filtros_buscador input::placeholder{
    font-size: 1.7rem;
}
.filtros_categoria, .banner-filtros-div-cat{
    .activo{
        margin: 0 1rem 1rem 1rem;
        height: 25rem;
        @include m.desktop{
            height: 20rem;
        }
    }
}
.filtros_categoria-opciones{
    display: flex;
    flex-direction: column;
    gap: .5rem;
    width: 95%;
    overflow-y: scroll;
    margin: 0 1rem 0rem 1rem;
    height: 0px;
    transition: all 1s ease;
    &::-webkit-scrollbar {
        width: 8px; /* Ancho del scrollbar */
        height: 20px; /* Altura del scrollbar (para scroll horizontal) */
    }

    /* Estilo del thumb del scrollbar (la parte que se desplaza) */
    &::-webkit-scrollbar-thumb {
        background: #888; /* Color del thumb */
        border-radius: 4px; /* Redondear bordes */
    }

    /* Estilo del track del scrollbar (la pista por donde se desplaza el thumb) */
    &::-webkit-scrollbar-track {
        background: #f1f1f1; /* Color del track */
    }

    /* Estilo del thumb del scrollbar en hover */
    &::-webkit-scrollbar-thumb:hover {
        background: #555; /* Color del thumb al pasar el ratón */
    }
    label{
        gap: .5rem;
        display: flex;
        align-items: center;
        font-weight: 200;
    }
}
.filtros_precio, .filtros_categoria{
    display: none;
    width: 100%;
    flex-direction: column;
    @include m.desktop{
        display: flex;
    }
}
.filtros_precio, .banner-filtros-div-pre{
    .activo{
        margin: 0 1rem 1rem 1rem;
        height: 3rem;
    }
}
.filtros_precio-opciones{
    transition: all .5s ease;
    overflow-y: hidden;
    display: flex;
    align-items: center;
    height: 0rem;
    width: 95%;
    margin: 0 1rem 0rem 1rem; 
    p{
        margin: 0 1rem;
    }
    div{
        width: 50%;
        input{
            width: 100%;
            font-weight: 200;
        }
    }
}
//filtros personalizados
input[type="checkbox"] {
    display: none;
}

/* Crear el nuevo estilo del checkbox */
.custom-checkbox {
    display: inline-block;
    width: 20px;
    height: 20px;
    background-color: #fff;
    border: 1px solid gray; /* Color del borde (naranja) */
    border-radius: 4px; /* Bordes ligeramente curvados */
    position: relative;
    cursor: pointer;
}

/* Estilo del icono del check */
.custom-checkbox:after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 10px;
    height: 10px;
    background-color: black; /* Color del check (naranja) */
    transform: translate(-50%, -50%) scale(0);
    border-radius: 2px; /* Bordes ligeramente curvados */
    transition: transform 0.2s ease-in-out;
}

/* Cambiar el estado del icono del check cuando el checkbox está seleccionado */
input[type="checkbox"]:checked + .custom-checkbox:after {
    transform: translate(-50%, -50%) scale(1);
}
.giro{
    rotate: 0deg !important;
}
.girar{
    rotate: 180deg ;
}
.buscar{
    text-align: center;
    text-transform: uppercase;
    padding: 1.5rem;
    color: white;
    width: 100%;
    background-color: black;
    border-radius: 3rem;
}
.ui-menu{
    z-index: 1050;
    max-width: 300px; /* Ajusta este valor para limitar el ancho de los resultados */
    word-wrap: break-word; 
    border-bottom: 1px solid black;/* Permite que el texto se ajuste al ancho */
    li{
        border-bottom: 1px solid black;/* Permite que el texto se ajuste al ancho */
    }
}
.busqueda-section{
    display: none;
    background-color: white;
    position: relative;
    overflow: hidden; 
    height: 100%;
    transition: all 1s ease-in-out;
    @include m.desktop{
       display: flex;
    }
}
.ocultar-buscador{
    position: absolute;
    top: 50%;
    left: 0px;
    border-radius: 0px 10px 10px 0px;
    background-color: white;
    z-index: 1010;
    padding: .5rem;
    height: 8rem;
    display: none;
    justify-content: center;
    align-items: center;
    img{
        transition: all .5s ease;
        width: 1.5rem;
        height: 3rem;
    }
    @include m.desktop{
        display: flex;
     }
}

.busqueda-carga{
    display: flex;
    justify-content: center;
    align-items: center;

    top: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.096);
}
#busquedaResultado{
    transition: all 1s ease-in-out;
    position: relative;
    color: black;
    background-color: white;
    flex-direction: column;
    overflow-y: auto;
    width: 100%;
    max-height: 100%;
    &::-webkit-scrollbar {
        width: 8px; /* Ancho del scrollbar */
        height: 20px; /* Altura del scrollbar (para scroll horizontal) */
    }

    /* Estilo del thumb del scrollbar (la parte que se desplaza) */
    &::-webkit-scrollbar-thumb {
        background: #888; /* Color del thumb */
        border-radius: 4px; /* Redondear bordes */
    }

    /* Estilo del track del scrollbar (la pista por donde se desplaza el thumb) */
    &::-webkit-scrollbar-track {
        background: #f1f1f1; /* Color del track */
    }

    /* Estilo del thumb del scrollbar en hover */
    &::-webkit-scrollbar-thumb:hover {
        background: #555; /* Color del thumb al pasar el ratón */
    }
    @include m.desktop{
        width: 40rem;
    }
    @include m.extra{
        width: 45rem;
    }
}
.busqueda{
    padding: 1rem 4rem;
    border-top: 1px solid gray;
    border-bottom: 1px solid gray;
    @include m.desktop{
        padding: 1rem .5rem;
    }
}
.busqueda:hover{
    cursor: pointer;
}
.sinresultados{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    img{
        max-width: 20rem;
    }
}
.busqueda-titulo{
    display: flex;
    align-items: center;
    justify-content: left;
    gap: 1rem;
    img{
        width: 5rem;
        height: 5rem;
    }
    p{
        font-size: 2rem;
        color: #b92729;
        font-weight: 400;
        @include m.tablet{
            font-size: 2.2rem;
        }
    }
}
.busqueda-direccion{
    margin: 1.5rem 0;
    p{
        font-size: 1.7rem;
        @include m.tablet{
            font-size: 2rem;
        }
    }
}
.busqueda-precioycategoria{
    display: flex;
    justify-content: space-between;
    align-items: center;
    p{
        font-size: 1.5rem;
    }
}
.filtros_avanzados{
    display: flex;
    button{
        background-color: black;
        color: white;
        padding: 1.5rem 2rem;
        border-radius: 1rem;
        border: none;
        cursor: pointer;
    }
    @include m.desktop{
        display: none;   
    }
}
.switch-div{
    display: flex;
    justify-content: center;
    align-items: center;
    p{
        font-size: 2rem;
        margin-right: .5rem;
    }
}
//Switch
.switch {
    position: relative;
    display: inline-block;
    width: 80px;
    height: 40px;
    @include m.desktop{
        display: none;
    }
  }
  
  /* Hide default HTML checkbox */
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  /* The slider */
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 32px;
    width: 32px;
    left: 5px;
    bottom: 3px;
    background-color: rgb(255, 255, 255);
    -webkit-transition: .4s;
    transition: .4s;
    background-image: url(../img/posicion.png);
    background-size: 2rem;
    background-repeat: no-repeat;
    background-position: center;

  }
  
  input:checked + .slider {
    background-color: #b92729;
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px #b92729;
  }
  
  input:checked + .slider:before {
    -webkit-transform: translateX(36px);
    -ms-transform: translateX(36px);
    transform: translateX(36px);
  }
  
  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
    border: 1px solid gray;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }

//filtros movil
.filtros-movil{
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.493);
    z-index: 1010;
    display: none;
    justify-content: center;
    align-items: center;
    .filtros_categoria,.filtros_precio{
        display: flex;
    }
    .filtros-opciones{
        z-index: 1010;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        background-color: white;
        width: 80%;
        color: black;
        padding: 4rem 2rem 2rem 2rem;
        position: relative;
        
    }
    .filtrosCerrar-x{
        position: absolute;
        right: 7px;
        top: 3px;
        padding: 1rem;
    }
    .filtrosCerrar-x:hover{
        cursor: pointer;
    }
    p{
        font-size: 2.5rem;
    }
    label{
        font-size: 2rem;
    }
    @include m.desktop{
        display: none;   
    }
}
.active{
    display: flex ;
}

.filtros-salida{
    position: absolute;
    width: 100%;
    height: 100%;

}



//animaciones de carga
.busqueda-carga{
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1010;
}
@keyframes ldio-x2uulkbinbj {
    0% { transform: translate(-50%,-50%) rotate(0deg); }
    100% { transform: translate(-50%,-50%) rotate(360deg); }
  }
  .ldio-x2uulkbinbj div {
    position: absolute;
    width: 90px;
    height: 90px;
    border: 20px solid #b51f29;
    border-top-color: transparent;
    border-radius: 50%;
  }
  .ldio-x2uulkbinbj div {
    animation: ldio-x2uulkbinbj 1s linear infinite;
    top: 100px;
    left: 100px
  }
  .loadingio-spinner-rolling-nq4q5u6dq7r {
    width: 200px;
    height: 200px;
    display: inline-block;
    overflow: hidden;

  }
  .ldio-x2uulkbinbj {
    width: 100%;
    height: 100%;
    position: relative;
    transform: translateZ(0) scale(1);
    backface-visibility: hidden;
    transform-origin: 0 0; /* see note above */
  }
  .ldio-x2uulkbinbj div { box-sizing: content-box; }

  //animacion de carga barras
  @keyframes ldio-yzaezf3dcmj-1 {
    0% { top: 36px; height: 128px }
    50% { top: 60px; height: 80px }
    100% { top: 60px; height: 80px }
  }
  @keyframes ldio-yzaezf3dcmj-2 {
    0% { top: 41.99999999999999px; height: 116.00000000000001px }
    50% { top: 60px; height: 80px }
    100% { top: 60px; height: 80px }
  }
  @keyframes ldio-yzaezf3dcmj-3 {
    0% { top: 48px; height: 104px }
    50% { top: 60px; height: 80px }
    100% { top: 60px; height: 80px }
  }
  .ldio-yzaezf3dcmj div { position: absolute; width: 30px }.ldio-yzaezf3dcmj div:nth-child(1) {
    left: 35px;
    background: #ba0c2f;
    animation: ldio-yzaezf3dcmj-1 1s cubic-bezier(0,0.5,0.5,1) infinite;
    animation-delay: -0.2s
  }
  .ldio-yzaezf3dcmj div:nth-child(2) {
    left: 85px;
    background: #ffcd00;
    animation: ldio-yzaezf3dcmj-2 1s cubic-bezier(0,0.5,0.5,1) infinite;
    animation-delay: -0.1s
  }
  .ldio-yzaezf3dcmj div:nth-child(3) {
    left: 135px;
    background: #f58016;
    animation: ldio-yzaezf3dcmj-3 1s cubic-bezier(0,0.5,0.5,1) infinite;
    animation-delay: undefineds
  }
  
  .loadingio-spinner-pulse-2by998twmg8 {
    width: 200px;
    height: 150px;
    display: none;
    overflow: hidden;
    background: #ffffff;
  }
  .ldio-yzaezf3dcmj {
    width: 100%;
    height: 100%;
    position: relative;
    transform: translateZ(0) scale(1);
    backface-visibility: hidden;
    transform-origin: 0 0; /* see note above */
  }
  .ldio-yzaezf3dcmj div { box-sizing: content-box; }

  .ocultar{
    display: none;
  }