@use"base/mixins" as m;
.navegador{
    padding: 1rem;
    display: flex;
    justify-content: space-around;
    align-items: center;
}
.navlogo{
    width: 4rem;
    @include m.tablet{
        width: 7rem;
    }
}
.nav_links{
    display: flex;
    gap: 3rem;
    font-weight: bold;
    a:hover{
        color:#b92729;
    }
}