@use"base/mixins" as m;
@use "base/variables" as v;
.mapa-section{
    display: flex;
    flex-direction: column;
    height: 100vh;
}
.mapa{
    overflow-y: hidden;
    height: 100%;
    display: flex;
    flex-direction: column;
    @include m.desktop{
        flex-direction: row;   
    }
}
#map{
    position: relative;
    flex: 1;
    display: flex;
    .busqueda-carga{
        display: flex;
        @include m.desktop{
            display: none !important;   
        }
    }
    @include m.desktop{
        display: flex !important;   
    }

}
//Estilos de agrupacion de pines
.marker-cluster-large{
    background-color: rgb(255 255 255) !important;
    div{
        background-color: black !important;
    }
}
.marker-cluster-medium{
    background-color: rgb(255 255 255) !important;
    div{
        background-color: rgb(80, 80, 80) !important;
    }
}
.marker-cluster-small{
    background-color: rgb(255 255 255) !important;
    div{
        background-color: rgb(142, 142, 142) !important;
    }
}
//Estilos del popup
.leaflet-popup-content{
    width: 30rem !important;
    @include m.tablet{
        width: 40rem !important;   
    }
}
.contenedor-popup{
    display:flex;
    justify-content:center;
    gap:1rem;
    .contenido{
        font-family: v.$fuentePrincipal;
        display:flex;
        flex-direction:column;
        justify-content:center;
        align-items: center;
        text-align: center;
        h2{
            text-transform: uppercase;
            margin:0;
            margin-bottom: 1.5rem;
            font-size: 2rem;
            @include m.tablet{
                font-size: 2.5rem;   
            }
        }
        p{
            font-size: 1.5rem;
            padding:0;
            margin:0;
            margin-bottom:.7rem;
            @include m.tablet{
                font-size: 1.7rem;   
            }
        }
        .direccion{
            text-transform: uppercase;
        }
    } 
}

.instaLink{
    background-color: #b92729;
    padding: 1rem;
    color: white;
    border-radius: .5rem;
    margin-top: 1rem;
    p{
        margin: 0 !important;
    }
}