@use"base/variables" as v;
@use"base/mixins" as m;
.img-fondo{
    background-color: white;
}
.kits{
    h1{
        padding-top: 5rem;
        background-color: #b92729;
        margin: 0;
        text-align: center;
        color: white;
        font-size: 4rem;
        @include m.tablet{
            font-size: 6rem;
        }
        span{
            font-family:'Anton', sans-serif;
            font-size: 3rem;
            color: white;
            font-weight: 900;
            @include m.telefono{
                font-size: 4rem;
            }
        }
    }
}
.containers{
    background-color: #b92729;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 10rem 5rem;
    padding: 8rem 5rem;
    .card{
        position: relative;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        width: 30rem;
        height: 30rem;
        background: white;
        border-radius: 2rem;
        box-shadow: 0 3.5rem 8rem rgba(0, 0, 0, 0.15);
        transition: 0.5s;
        @include m.telefono{
            width: 40rem;
            height: 40rem;
        }
        &:hover{
            height: 27rem;
            @include m.telefono{
                height: 40rem;
            }
            .imgBx{
                top: -5rem;
                scale: 0.9;
                box-shadow: 0 1.5rem 4.5rem rgba(0, 0, 0, 0.2);
                @include m.telefono{
                    top: -7rem;
                }
            }
            .content{
                top: 12.5rem;
                height: 15rem;
                @include m.telefono{
                    top: 20rem;
                    height: 20rem;
                }
            }
        }
        .imgBx{
            position: absolute;
            top: 2rem;
            width:  25rem;
            height: 15rem;
            background: #333;
            border-radius: 1.2rem;
            overflow: hidden;
            transition: 0.5s;
            @include m.telefono{
                width: 35rem;
                height: 25rem;
            }
            img{
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        .content{
            position: absolute;
            top: 20rem;
            width: 100%;
            padding: 0 30px;
            height: 8rem;
            overflow: hidden;
            text-align: center;
            transition: 0.5s;
            @include m.telefono{
                top: 28.5rem;
            }
            h2{

                font-weight: 700;
                color: black;
                margin: 0;
                font-size: 2.5rem;
                span{
                    font-size: 3rem;
                    @include m.telefono{
                        font-size: 3.5rem;
                    }
                }
                @include m.telefono{
                    font-size: 3rem;
                }
            }
            a{
                font-family: 'Anton', sans-serif;
                font-size: 2rem;
                color: white;
                position: relative;
                top: 3rem;
                display: inline-block;
                padding: 1rem 2rem;
                background-color: #cc0100;
                font-weight: 500;
                text-decoration: none;
                border-radius: 1rem;
                @include m.telefono{
                    padding:1.2rem 2.5rem;
                }

            }
        }
    }
}
