.nosotros{
    h1{
        text-align: center;
    }
    .banner-nosotros{
        height: 70rem;
        background-image: url(../img/nosotros2.webp);
        background-position: 50% 35%;
        background-size: cover;
        background-repeat: no-repeat;
        max-width: 60rem;
        margin: 0 auto;
    }
}
.nosotros-descripcion{
    text-align: center;
    padding: 5rem 1rem;
    max-width: 100rem;
    margin: 0 auto;
    h2{
        margin: 0;
        margin-bottom: 3rem;
    }
    p{
        text-align: justify;
        max-width: 80rem;
        margin: 0 auto;
    }
}